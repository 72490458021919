

.submitBlogItem {
    padding: 4px;
    border: 1px solid transparent;
    opacity: 0.54;
    transition: 0.3s all;
    .cover {
        width: 84px;
        height: 48px;
        object-fit: cover;
        margin-bottom: 4px;
    }
}
.submitBlogItem:hover {
    opacity: 1;
}
.submitBlogItem.selected {
    border: 1px solid red;
    opacity: 1;
}
