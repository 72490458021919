.App {
    text-align: center;
    background-color: #000000;
    color: #ffffff;
}
.commonRoom {
    flex: 1;
    border: 1px solid white;
    
}

.dHorizontal {
    display: flex;
    flex-direction: row;
}
.show {
    border-top: 1px solid white;
}
.m100 {
    width: 100%;
}
.smallText {
    font-size: 10px;
}
li {
    margin-bottom: 8px;
    font-size: 12px;
}
.dashModal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    border-radius: 0px;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 720px;
    padding: 0;
    height: auto;
    //border-radius: 16px;
    padding: 48px;

    border: 1px solid #ccc;
    background: #000;
    color: #fff;
    overflow: auto;
    //border-radius: 0px;
    outline: none;

    
    position: absolute;
}
.editButton {
    padding: 8px;
    border: 1px solid #E82755;
    cursor: pointer;
    transition: .3s all;
    font-size: 8px;
}
.topBox {
    border: 1px solid black;
    padding: 16px;
    width: 30%;
    background-color: rgba(255,255,255,.15);
}
.editButton:hover {
    opacity: .54;
}
.thumb {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  